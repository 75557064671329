import React, {useState, useRef, useLayoutEffect } from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image";
import { Box, Flex, Text } from '@sqymagma/elements'
import { useStaticQuery, graphql } from "gatsby";
import { theme } from '../utils/themeGet'
import IconSystem from "../components/IconSystem"

const GalleryPage = ({ data }) => {
	const carouselImages = useStaticQuery(graphql`
	query {
		data: allFile(filter: {relativeDirectory: {eq: "carousel"}}) {
			edges {
                 node {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 2400) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64

                        }
                    }
                 }
            }
        }
    }`);

    const customRenderThumb = (children) =>
    children.map((item) => {
        const imageData = item.props.children.props.fluid;
        return (
            <BackgroundImage Tag="section" fluid={imageData}
			style={{ 
                backgroundSize: "cover", 
                backgroundPosition: "center", 
                position: "relative",
                width: "100px",
                height: "100px"
            }}
            />
        );
    });


  return (
   <Box css={`{
        .carousel-root {
           display: flex;
           flex-direction: column-reverse;
           background: ${theme('color.ui07')};
        }

        .carousel {
            border-top:1px solid ${theme('color.ui07')};
            .thumbs-wrapper {
                margin: 12px;
            }
            li.thumb {
                height: 80px;
                padding: 0;
                border: none;
                opacity: .6;

                &.selected {
                    border: 1px solid #fff;
                    opacity: 1;
                }
            }
        }
   }`}>
        <ParallaxButton zIndex="2" position="fixed" top="16px" right="24px"/>
        <Carousel 
            showArrows={false} dynamicHeight={true} showThumbs={true} showStatus={false}
            swipeable={true} renderThumbs={customRenderThumb}>
            {carouselImages.data.edges.map((item, idx) => (
                <div key={idx}>
                <Img fluid={item.node.childImageSharp.fluid} />
            </div>
            ))}
        </Carousel>
   </Box>
    )
};

export default GalleryPage;

const DownloadButton =  _ => (
    <Box position="relative" overflow="hidden"
        css={`{
            cursor: pointer;
            &:hover {
                overflow: visible;
                transition: all ease .3s;
                .text-box {
                    transition: all ease .3s;
                    transform: translate(-90%, -50%);
                    width: auto;
                    opacity: 1;
                }
            }
        }`}
    >
        <Flex borderRadius="50%" width="80px" height="80px" bg="brand04" 
        position="relative" zIndex="1" alignItems="center" justifyContent="center"
        css={`{
            box-shadow: 0 0 4px ${theme('color.ui08')};
        }`}
        >
        <Flex borderRadius="50%" width="50px" height="50px" bg="ui09" alignItems="center" justifyContent="center"
            css={`{
                border: 1px solid ${theme('color.ui08')};
            }`}
        >
            <IconSystem name="download" height="24px" fill="text01"/>
        </Flex>
    </Flex>
     <Box className="text-box" position="absolute" left="0" opacity="0" top="50%" bg="brand01" color="text06" width="0"
        css={`{
            transform: translate(-50%, -50%);
            border-radius: 9px;
            padding: 0 24px 3px 12px;
        }`}
    >
        <Text
            textStyle="xs"
            css={`{
                white-space: nowrap;
            }`}
        >Descarga este material</Text>
    </Box>
    </Box>
)


const ParallaxButton = ({ src, ...style }) => {
    const [elementTop, setElementTop] = useState(0);
    const ref = useRef(null);
    const { scrollY } = useViewportScroll();
  
    const y = useTransform(scrollY, [elementTop, elementTop + 9], [0, +1], {
        clamp: false
    });
    const opacity = useTransform(y,[0, 15, 30], [0, 0, 1])
  
    useLayoutEffect(() => {
      const element = ref.current;
      setElementTop(element.offsetTop);
    }, [ref]);
  
    return (
      <div ref={ref}>
        <motion.div style={{ ...style, y, opacity }}>
            <DownloadButton/>
        </motion.div>
      </div>
    );
  };